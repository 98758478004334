<template>
  <div class="app flex-row align-items-center bg-gray-50">
    <Toast />
    <div class="container flex relative justify-center h-screen items-center bg-gray-50 text-left">
     <div class="w-2/5">
        <div class="flex justify-center">
          <img
            src="../../assets/img/gramedia_digital.png"
            alt="Gramedia Digital"
            class="mb-4"
          />
        </div>
        <div class="rounded-lg h-auto p-4 border shadow-md flex justify-center bg-white">
          <div class="flex-col w-full justify-center">
            <h4 class="font-bold mt-4 mb-3">Enter your registered email</h4>
            <p class="text-base">We will send you the link that will direct you to reset the password.</p>
            <div class="mt-0 w-full">
              <label for="email" class="block w-full mb-2 text-sm font-medium text-gray-700">Email</label>
              <input 
                type="text"
                class="shadow-sm text-gray-500 bg-gray-50 border p-2.5 w-full rounded-lg"
                placeholder="name@example.com"
                autocomplete="email"
                @input="validateEmail"
                v-model="Form.email"
                :class="errors.email ? 'bg-red-50 border-red-500' : 'border'"
                required
              />
              <div v-if="errors.email" class="text-red-500 text-sm mt-2">{{ errors.email }}</div>
              <button 
                :disabled="!isEmailValid" 
                :class="!isEmailValid ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500'" 
                class="text-white w-full p-2.5 mt-4 mb-2 rounded-md"
                @click="actionForget"
                >
                Send
                <svg v-if="isLoading" aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                </svg>
               </button>
              <router-link :to="{ path: '/'}">
                <button class="text-blue-500 w-full p-2.5 mt-0 mb-2 rounded-md" >Back</button>
              </router-link>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Toast from '../../components/form/Toast.vue'
import { EventBus } from '../../../src/event-bus';  

export default {
  name: "forgotPassword",
  components: {
        Toast
    },
  data() {
    return {
      Form: {
        email: ''
      },
      errors: {
        email: null
      },
      isActive: false,
    };
  },
  computed: {
    ...mapState({
      success: (state) => state.forgetPassword.isSuccess,
      message: (state) => state.forgetPassword.successMessage,
      isLoading: (state) => state.forgetPassword.isLoading,
      statusCode: (state) => state.forgetPassword.statusCode,
      isError:(state) => state.forgetPassword.isError,
      
    }),
    isEmailValid() {
      return this.errors.email === null && this.Form.email !== '';
    },
    isDisable() {
      return !(this.Form.email && this.Form.email.length > 4);
    }
  },
  watch: {
    $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Forgot Password | Content Management System Ebooks Gramedia.com';
        }
    },
    success: function () {
      if(this.success){
        EventBus.$emit('show-toast', {
          message: this.message ==='Please Check Your Email To Verify' ? 'The link has been sent.' : this.message,
          type: 'success',
          });
      }
    },
    isError : function (){
      if(this.isError){
        EventBus.$emit('show-toast', {
          message: this.message,
          type: 'success',
          });
      }
    }
  },
  methods: {
    ...mapActions("forgetPassword", ["forgetPasswordAdapter"]),
    actionForget() {
       let payload = {
        email: this.Form.email,
      };
      this.forgetPasswordAdapter(payload)
    },
   
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.Form.email === '') {
        this.errors.email = 'Must be filled.';
      } else if (!emailPattern.test(this.Form.email)) {
        this.errors.email = 'Please enter a valid email address';
      } else {
        this.errors.email = null;
      }
    }
  },
  mounted() {},
};
</script>
<style lang="css" scoped>
.center {
  width: 500px;
  height: 400px;
  top: 20%;
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}
.form-control {
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
}
input.form-control:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
@media (min-width: 360px) and (max-width: 768px) {
  .background-mobile {
    background-color: #fff;
  }
  .display-mobile {
    display: none;
  }
}

</style>
